var API_ENDPONT = 'https://api-console-v2.uwellcare.com'
// var API_ENDPONT = 'http://localhost:41004'

export default {
    URL_LINK: '',
    AUTH_PREFIX: 'Bearer ',
    API_LOGIN: API_ENDPONT + "/login",
    API_STAFF: API_ENDPONT + "/staff",
    API_REGISTER: API_ENDPONT + "/register",
    API_POPUP: API_ENDPONT + "/popup",
    API_SEO: API_ENDPONT + "/seo",
    API_FAQ: API_ENDPONT + "/faq",
    API_CONTENT: API_ENDPONT + "/content",
    API_GALLERY : API_ENDPONT + "/gallery",
    API_CALLACTION : API_ENDPONT + "/call-action",
    API_INFORMATION : API_ENDPONT + "/information",
    API_CONTACT : API_ENDPONT + "/contact",
    API_SERVICE : API_ENDPONT + "/service",
    API_PERSONNEL : API_ENDPONT + "/personnel",
    API_BANNER : API_ENDPONT + "/banner",
    API_CARD : API_ENDPONT + "/card",
    API_NEWS : API_ENDPONT + "/news",
    API_BRANCH : API_ENDPONT + "/branch",
    API_REPORT_PROBLEM : API_ENDPONT + "/report/problem",
}